//      

const browser = require('../util/browser');
const CollisionIndex = require('../symbol/collision_index');

                                              
                                            
                                                      

class LayerPlacement {
                              
                            

    constructor(tileIDs               ) {
        this._currentTileIndex = 0;
        this._tileIDs = tileIDs;
    }

    continuePlacement(sourceCache, collisionIndex, showCollisionBoxes         , layer, shouldPausePlacement) {
        while (this._currentTileIndex < this._tileIDs.length) {
            const tile = sourceCache.getTileByID(this._tileIDs[this._currentTileIndex]);
            tile.placeLayer(showCollisionBoxes, collisionIndex, layer, sourceCache.id);

            this._currentTileIndex++;
            if (shouldPausePlacement()) {
                return true;
            }
        }
    }
}

class Placement {
                                   
                   
                                   
                                 
                                 
                        
                             
                                      
                                                   

    constructor(transform           , order               ,
            forceFullPlacement         , showCollisionBoxes         , fadeDuration        ,
            previousPlacement            ) {

        this.collisionIndex = new CollisionIndex(transform.clone());
        this._currentPlacementIndex = order.length - 1;
        this._forceFullPlacement = forceFullPlacement;
        this._showCollisionBoxes = showCollisionBoxes;
        this._sourceCacheTileIDs = {};
        this._done = false;

        if (forceFullPlacement || !previousPlacement || fadeDuration === 0) {
            this._delayUntil = browser.now();
        } else {
            this._delayUntil = previousPlacement._delayUntil + 300;
        }

        if (previousPlacement) {
            this._collisionFadeTimes = previousPlacement._collisionFadeTimes;
        } else {
            this._collisionFadeTimes = {
                latestStart: 0,
                duration: fadeDuration
            };
        }
    }

    isDone()          {
        return this._done;
    }

    continuePlacement(order               , layers                        , sourceCaches                         ) {
        const startTime = browser.now();

        if (startTime < this._delayUntil) return true;

        const shouldPausePlacement = () => {
            const elapsedTime = browser.now() - startTime;
            return this._forceFullPlacement ? false : elapsedTime > 2;
        };

        while (this._currentPlacementIndex >= 0) {
            const layerId = order[this._currentPlacementIndex];
            const layer = layers[layerId];
            if (layer.type === 'symbol') {
                const sourceCache = sourceCaches[layer.source];

                if (!this._inProgressLayer) {
                    if (!this._sourceCacheTileIDs[layer.source]) {
                        this._sourceCacheTileIDs[layer.source] = sourceCache.getRenderableIds().sort((a, b) => {
                            const aCoord = sourceCache.getTileByID(a).tileID;
                            const bCoord = sourceCache.getTileByID(b).tileID;
                            if (aCoord.isLessThan(bCoord)) {
                                return -1;
                            } else if (bCoord.isLessThan(aCoord)) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                    }
                    this._inProgressLayer = new LayerPlacement(this._sourceCacheTileIDs[layer.source]);
                }

                const pausePlacement = this._inProgressLayer.continuePlacement(sourceCache, this.collisionIndex, this._showCollisionBoxes, layer, shouldPausePlacement);

                if (pausePlacement) {
                    // We didn't finish placing all layers within 2ms,
                    // but we can keep rendering with a partial placement
                    // We'll resume here on the next frame
                    return;
                }

                delete this._inProgressLayer;
            }

            this._currentPlacementIndex--;
        }

        for (const id in sourceCaches) {
            sourceCaches[id].commitPlacement(this.collisionIndex, this._collisionFadeTimes);
        }

        this._done = true;

    }

    stillFading() {
        return browser.now() < this._collisionFadeTimes.latestStart + this._collisionFadeTimes.duration;
    }

}

module.exports = Placement;
